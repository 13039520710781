<template>
  <div class="echartsContainer" v-if="fros">
    <div id="linbox" :ref="myCharts" style="height: 400px"></div>
  </div>
  <template v-else>
    <el-empty description="暂无数据" />
  </template>
</template>

<script>
import { reactive, ref, toRefs, onMounted, onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import { findData, findRateOfReturn } from "@/components/Homecard/index";

var myChart;
export default {
  name: "livenessCard",
  setup() {
    let refs = "";
    let fros = ref(true)
    const myCharts = (el) => {
      refs = el;
    };
    const options = reactive({
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["2021-01", "2021-02", "2021-03", "2021-04", "2021-05"],
      },
      yAxis: {
        type: "value",
      },
      // legend: {
      //   show:true,
      //   orient: "bottom",
      //   left: "5%",
      //   data: [

      //     {
      //       name: "11",
      //       icon: "circle",
      //     },
      //     {
      //       name: "22",
      //       icon: "circle",
      //     },
      //   ],
      //   formatter: function (row) {
      //     console.log('1111')
      //           // console.log(newRes,newRes[0].fileCount,row,sum,row + params[0].fileCount)
      //           return '33'//`{${row}${params[0].fileCount}`; //`<div class="display flex "><div>${row}</div><div>${params[0].fileCount}</div></div>`
      //         },
      // },
      legend: {
        orient: "bottom",
        left: "5%",
        bottom: "15%",
        icon: "circle",
        itemGap: 20,
        lineHeight: 10,
        data: [
          {
            name: "11",
            icon: "circle",
          },
          {
            name: "22",
            icon: "circle",
          },
        ],
        formatter: function (row) {
          console.log("9888");
          return `111`;
        },
        textStyle: {
          color: "#03032C",
        },
      },
      series: [
        {
          data: [200, 932, 500, 934, 800],
          type: "line",
          areaStyle: {
            color: "#FEAE10",
            opacity: 0.25,
          },
          smooth: true,
          lineStyle: {
            color: "#FEAE10",
          },
          itemStyle: {
            color: "#00FF00",
          },
          symbol: "none", // 去除小圆点
        },
      ],
    });

    const echartsresize = () => {
      myChart.resize();
    };
    let getlist = (acidMaps) => {
      //设置
      if (!acidMaps) {
        return [];
      }
      let list = JSON.parse(sessionStorage.getItem("groupRanking"));
      let newList = [];
      let date = []; //y轴数据
      list.forEach((item) => {
        if (acidMaps[item.acid]) {
          acidMaps[item.acid].sort(function (a, b) {
            return a.dataTime > b.dataTime ? 1 : -1;
          });
          let acidObj = {
            name: item.groupName,
            date: acidMaps[item.acid].map((v) => {
              return v.dataTime;
            }),
            count: acidMaps[item.acid].map((v) => {
              return v.freturn === null ? 0 : (v.freturn * 10000) / 100;
            }),
          };
          date.push(...acidObj.date);
          newList.push(acidObj);
          date = [...new Set(date)];
          newList[0].date = date;
        }
      });
      // console.log(newList, "newList");
      return newList;
    };
    onMounted(() => {
      // getChartsList()
      window.addEventListener("resize", echartsresize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", echartsresize);
    });

    let getChartsList = () => {
      findRateOfReturn({
        classId: sessionStorage.getItem("classId"),
      }).then((res) => {
        if (res.data.code === "200") {
          let seriesList = []
          seriesList = getlist(res.data.data.acidMaps);
          if (seriesList.length > 0) {
            fros.value = true;
          } else {
            fros.value = false;
          }
          let option = {
            tooltip: {
              trigger: "axis",
              formatter: function (c) {
                let str = c[0]?.axisValueLabel + "<br />" || "";
                let temp = {
                  bgcricle: "", //颜色
                  seriesName: "", //学校名
                  valueData: "", //数值
                  spacespan: "",
                };
                c.forEach((item) => {
                  // 做的事情
                  temp.bgcricle = item.marker;
                  temp.seriesName = item.seriesName;
                  temp.valueData = item.value + "%";
                  temp.spacespan =
                    '<span style="display:inline-block;10px;height:15px;margin-left:15px;margin-top:5px"></span>';
                  str +=
                    temp.bgcricle +
                    temp.seriesName +
                    temp.spacespan +
                    temp.valueData +
                    "<br />";
                });
                return str;
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: seriesList[0]?seriesList[0].date:[],
              splitLine: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#666666",
                  padding: 10,
                },
                onZero: false
              },
              axisLabel: {
                margin: 20,
              },
              axisTick: {
                show: false
              }
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value} %",
              },
              splitLine: {
                show: false,
              },
            },
            series: [],
          };
          seriesList.forEach((item) => {
            option.series.push({
              data: item.count,
              type: "line",
              name: item.name,
              smooth: true,
            });
          });
          if( option.series[0]){
            option.series[0].markLine = {
            symbol: "none",
            data: [
              {
                type: "average",
                name: "Avg",
                yAxis: 0,
                lineStyle: { color: "#FF8982", width: 2 },
              },
            ],
          };
          }

          let name = [];
          option.series.forEach((item) => {
            name.push(item.name);
          });
          option.legend = {
            orient: "horizontal",
            right: "5%",
            top: "0%",
            icon: "circle",
            itemGap: 20,
            data: name,
            itemHeight: 8,
          };
          console.log(option);
          myChart = echarts.init(refs);
          myChart.setOption(option);
        }
      });
    };

    return {
      options,
      myCharts,
      getlist,
      getChartsList,
      fros,
    };
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 100%;
  height: 100%;

  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }
}

.echartsContainer {
  width: 100%;
  height: 100%;
  margin-top: -20px;

  #linbox {
    width: 100%;
    height: 250px;
  }
}
</style>
