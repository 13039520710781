<template>
  <div style="height: 380px; width: 360px">
    <e-charts
      v-if="show"
      :heightCharts="heightCharts"
      :option="option"
    ></e-charts>
    <el-empty v-else description="暂无报告"></el-empty>
  </div>
</template>

<script>
import ECharts from "@/components/Echarts";
import { onMounted, reactive, toRefs } from "vue";
import { findFileGroupByType } from "../../homeApi";
import dayjs from "dayjs";

export default {
  name: "pieChart",
  components: {
    ECharts,
  },
  setup() {
    let data = reactive({
      option: {},
      heightCharts: 360,
      show: true,
    });
    onMounted(() => {
      getChart();
      // groupIndex()
    });
    let getChart = () => {
      findFileGroupByType({
        classId: sessionStorage.getItem("classId"),
        excludeState: [1000],
      }).then((res) => {
        if (res.data.code === "200") {
          let newRes = res.data.data.fileTypeGroups;
          let name = [];
          let count = [];
          let dataPie = [];
          let sum = 0;
          if (newRes.length === 0) return (data.show = false);
          for (let i in newRes) {
            dataPie[i] = {
              name: newRes[i].typeName,
              value: newRes[i].fileCount,
            };
            name.push(newRes[i].typeName);
            count.push(newRes[i].fileCount);
            sum += newRes[i].fileCount;
          }
          const colorList = [
            "#6F80FF ",
            "#CE79FF",
            "#FF7A6C",
            "#FBD444",
            "#7F6AAD",
          ];

          var option = {
            title: {
              text: sum,
              subtext: "总量",
              textStyle: {
                fontSize: 20,
                color: "#03032C",
                lineHeight: 18,
                padding: [0, 0, 0, 0],
                padding: [0, 0, 0, 0],
               
              },
              itemGap: 3,
              subtextStyle: {
                fontSize: 14,
                color: "#03032C",
              },
              textAlign: "center",
              left: "48%",
              top: "22%",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "bottom",
              left: "5%",
              bottom: "15%",
              icon: "circle",
              itemGap: 20,
              data: name,
              itemHeight: 10,
              formatter: function (row) {
                let params = newRes.filter((item) => {
                  if (item.typeName == row) {
                    return item;
                  }
                });
                // console.log(newRes,newRes[0].fileCount,row,sum,row + params[0].fileCount)
                return `{labelname|${row}} ${params[0].fileCount}` //`{${row}${params[0].fileCount}`; //`<div class="display flex "><div>${row}</div><div>${params[0].fileCount}</div></div>`
              },
              textStyle: {
                color: '#03032C',
                rich: {
                  labelname: {
                    fontSize: 14,
                    width: 270,
                   
                  },
                },
              },
            },
            color: colorList,
            series: [
              {
                name: "类型",
                type: "pie",
                radius: [60, 90],
                center: ["50%", "30%"],
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  borderWidth: 3,
                  borderColor: "#fff",
                },
                data: dataPie,
              },
            ],
          };

          data.option = option;
        }
      });
    };
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped>
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.filecount {
  color: red;
}
</style>
