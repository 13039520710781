<template>
  <div class="home-manage">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card" style="min-height: 300px">
          <div style="margin: 10px"  class="box-title ">
           收益率
          </div>
          <line-chart ref="RefChart"></line-chart>
        </el-card>
      </el-col>
    </el-row>
      <div class="main">
        <div class="research-report-warehouse">
          <div style="margin: 10px"  class="box-title ">
            研报仓库
          </div>
          <pie-chart></pie-chart>
        </div>
        <!-- <el-card class="box-card" style="height: 470px"> -->
          <div class="group-ranking">
          <div style="margin: 10px"  class="box-title ">
            小组排名
          </div>
          <rank-card @onGroup="onGroup"></rank-card>
        </div>
   
    </div>
    <div>
          <div style="margin: 10px"  class="box-title ">
            优秀研报
            <div class="more" style="float: right;"
              @click="moreRou">
              更多
            </div>
          </div>
          <good-report></good-report>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import lineChart from "./components/LineChart";
import pieChart from "./components/PieChart";
import rankCard from "./components/RankCard";
import goodReport from "./components/GoodReport";
import { useRouter } from "vue-router";
import { getAcidByclassID } from "./homeApi.js";

export default {
  name: "index",
  components: {
    lineChart,
    pieChart,
    rankCard,
    goodReport
  },
  setup() {
    let router = useRouter()
    let RefChart = ref()
    onMounted(() => {
      getCourseAcid();
    })

    let moreRou = () => {
      router.push({ path: '/course/report/browser' })
    }

    let getCourseAcid = () => {
      var parame = {
        classId: sessionStorage.getItem('classId'),
        classGroupID: JSON.parse(sessionStorage.getItem("groupId")),
        acidType: 1
      };
      getAcidByclassID(parame).then(res => {
        sessionStorage.setItem('acid', res.data.data)
      })
    }
    let onGroup = (type) => {
      if (type) {
        RefChart.value.getChartsList();
      }

    }
    return {
      moreRou,
      onGroup,
      RefChart
    }
  }
}
</script>

<style scoped lang="less">
.research-report-warehouse{
  box-sizing: border-box;
  padding-top:20px;
  /* margin-right: 30px; */
  background: #FBFBFB;
  border-radius: 6px;
  height: 420px;
  width: 360px;
  
}
.main{
  margin:40px 0px;
  display: flex;
  .research-report-warehouse{
    margin-right: 30px;
  }
  .group-ranking{
    // flex:1;
    width: calc(100% - 390px);
  }
}
.group-ranking{
  box-sizing: border-box;
  height: 420px;
  padding-top:10px;
  border: 1px solid #D5D7E6;
  border-radius: 6px;
}
.home-manage{
  height: calc(100vh - 320px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.more{
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B6EFB;
    line-height: 26px;
    width: 68px;
    height: 28px;
    text-align: center;
    background: #F9FAFF;
    border-radius: 4px;
    border: 1px solid #3B6EFB;
    cursor: pointer;
  }
  .main-content{
    // margin:0px !important;
  }
</style>
